import qs from 'qs'
import { of } from 'rxjs'
import { Observable } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, distinctUntilChanged, map, pluck, retry, switchMap, tap } from 'rxjs/operators'

const cache: { [key: string]: { [locale: string]: string } } = {}

const doTranslate$ = (
  text: string,
  tl: string,
  sl: string,
): Observable<string> => {
  const hostname = 'prod-bridge.baopals.com'
  const url = `https://${hostname}/api/v1/translation`
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': 'ByK2R6Os3F/KtvVwXeR2xP7C0OkDoJ56tXyVHa7UMIw=',
    'accept': 'application/json'
  }
  const body = {
    text,
    targetLanguage: tl,
    sourceLanguage: sl,
  }
  return ajax({
    url,
    method: 'POST',
    headers,
    body,
    withCredentials: true
  })
    .pipe(
      pluck('response', 'translatedText'),
      map(result => {
        if (!result) {
          throw new Error(`Error during translate ${text}`)
        }

        return result as string
      }),
      // retry(2)
    )
}

export const translate = (
  text: string,
  tl: string,
  sl = 'auto',
  chatGPTOpts: any
): Observable<string> => {
  return of({ text, tl, sl }).pipe(
    distinctUntilChanged(),
    switchMap(query => {
      // Return empty string when the text contains nothing
      if (!query.text) {
        return of('')
      }

      // Translate CJK only
      if (query.tl === 'en' && !query.text.match(/[\u3400-\u9FBF]/)) {
        return of(query.text)
      }

      // Do NOT translate when the text of the source language are the same with target language
      // TODO: RU, ZH-CN
      if (query.tl === 'ko' && query.text.match(/[가-힣]+/)) {
        return of(query.text)
      }

      // Skip sending req when the result exist in the cache
      if (cache[query.text] && cache[query.text][query.tl]) {
        return of(cache[query.text][query.tl])
      }

      return doTranslate$(query.text, query.tl, query.sl)
    }),
    tap(result => {
      // Store result to the cache
      cache[text] = { ...cache[text], [tl]: result }
    }),
    catchError(err => of(text))
  )
}
